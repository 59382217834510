'use client';

import { useState } from 'react';
import { Form } from '@/app/components/shared/Form';
import { SubmitButton } from '@/app/components/shared/SubmitButton';
import { handleLogin } from '@/app/actions/auth';
import { sendGAEvent, GA_EVENTS } from '@/app/lib/analytics';
import Link from 'next/link';
import { useRouter } from 'next/navigation';

// Registration can be controlled via NEXT_PUBLIC_ENABLE_REGISTRATION environment variable
const ENABLE_REGISTRATION = process.env.NEXT_PUBLIC_ENABLE_REGISTRATION === 'true';

export default function LoginForm() {
  const [error, setError] = useState<string | null>(null);
  const router = useRouter();

  async function onSubmit(formData: FormData) {
    try {
      setError(null);
      const email = formData.get('email')?.toString() ?? '';
      
      sendGAEvent({
        ...GA_EVENTS.AUTH.LOGIN,
        label: email,
      });
      
      const result = await handleLogin(formData);
      
      if ('error' in result) {
        setError(result?.error || 'An unexpected error occurred');
        return { error: result.error, success: false };
      }
      
      // Return success before navigation to maintain loading state
      const response = { success: true };
      router.push(result.redirectPath as any);
      return response;
    } catch (e) {
      setError('An unexpected error occurred');
      return { error: 'An unexpected error occurred', success: false };
    }
  }

  return (
    <>
      {error && (
        <div className="flex justify-center w-full">
          <div className="bg-red-100 border border-red-400 text-red-700 px-3 py-2 mt-2 rounded-md mb-0 block w-80 appearance-none" role="alert">
            <span className="block sm:inline text-sm">{error}</span>
          </div>
        </div>
      )}
      <Form action={onSubmit}>
        <SubmitButton defaultText="Sign in" pendingText="Signing in..." />
        {ENABLE_REGISTRATION && (
          <p className="text-center text-sm text-sourced-black mt-4">
            {"Don't have an account? "}
            <Link href="/register" className="text-sourced-black hover:text-sourced-pink transition-colors">
              Sign up
            </Link>
            {' for free.'}
          </p>
        )}
      </Form>
    </>
  );
}
