'use client';

import { useRouter } from 'next/navigation';
import { useFormStatus } from 'react-dom';

export function Form({
  action,
  children,
}: {
  action: (formData: FormData) => Promise<{ error?: string, success?: boolean } | undefined>;
  children: React.ReactNode;
}) {
  const router = useRouter();

  return (
    <form
      action={async (formData) => {
        const result = await action(formData);
        if (result?.success) {
          router.push('/');
        }
      }}
      className="flex flex-col space-y-4 bg-sourced-white px-4 py-8 sm:px-16"
    >
      <div>
        <label
          htmlFor="email"
          className="block text-xs text-sourced-black uppercase"
        >
          Email Address
        </label>
        <input
          id="email"
          name="email"
          type="email"
          placeholder="user@acme.com"
          autoComplete="email"
          required
          className="mt-1 block w-full appearance-none border border-sourced-black px-3 py-2 placeholder-gray-400 rounded-md bg-sourced-white text-sourced-black focus:outline-none focus:ring-1 focus:ring-sourced-black sm:text-sm"          
        />
      </div>
      <div>
        <label
          htmlFor="password"
          className="block text-xs text-sourced-black uppercase"
        >
          Password
        </label>
        <input
          id="password"
          name="password"
          type="password"
          required
          className="mt-1 block w-full appearance-none border border-sourced-black px-3 py-2 placeholder-gray-400 rounded-md bg-sourced-white text-sourced-black focus:outline-none focus:ring-1 focus:ring-sourced-black sm:text-sm"
        />
      </div>
      {children}
    </form>
  );
}
